<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    src="../../assets/login-background.jpg"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-content>
          <img
            src="../../assets/imparfaite-white-logo.svg"
            alt="Imparfaite."
            height="30"
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />
    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in items">
        <v-list-item
          :key="`item-${i}`"
          :to="item.to"
          link
          class="mb-0"
        >
          <v-list-item-icon class="mr-2">
            <v-icon v-if="item.icon">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <template v-for="(child, j) in item.items">
          <v-list-item
            :key="`child-item-${i}-${j}`"
            :to="child.to"
            link
            class="py-0"
            style="max-height: 32px;height: 32px;min-height: 32px"
          >
            <v-list-item-icon class="mr-2 mt-2">
              <v-icon
                v-if="child.icon"
                small
              >
                {{ child.icon }}
              </v-icon>
            </v-list-item-icon>
            
            <v-list-item-subtitle
              class="white--text"
            >
              {{ child.title }}
            </v-list-item-subtitle>
            
            <v-list-item-action>
              <v-btn
                v-if="child.action"
                :to="child.action.to"
                small
                icon
              >
                <v-icon small>
                  {{ child.action.icon }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <Item
        :item="{
          title: $t('menu.logout'),
          icon: 'mdi-logout',
          onClick:logout
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import Item from '@/components/base/Item.vue'
import { mapState } from 'vuex'

export default {
  name: 'CoreDrawer',
  components: { Item },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapState(['barColor', 'roles']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('drawerDisplay', val)
      },
    },
  },
  mounted: function () {
    this.items = [
      {
        icon: 'mdi-chart-box-outline',
        title: this.$i18n.t('menu.dashboard'),
        to: '/',
      },
      {
        icon: 'mdi-store-outline',
        title: this.$i18n.t('menu.stores.list'),
        items: [
          {
            title: this.$i18n.t('menu.stores.list'),
            to: '/stores/list',
            action : {
              icon: 'mdi-plus-circle',
              title: this.$i18n.t('menu.stores.add'),
              to: '/stores/add',
            },
          },
          {
            title: this.$i18n.t('menu.sellers.list'),
            to: '/sellers/list',
          },
        ],
      },
      {
        icon: 'mdi-hanger',
        title: this.$i18n.t('menu.catalog.list'),
        items: [
          {
            title: this.$i18n.t('menu.products.list'),
            to: '/products/list',
            action : {
              icon: 'mdi-plus-circle',
              title: this.$i18n.t('menu.products.add'),
              to: '/products/add',
            },
          },
          {
            title: this.$i18n.t('menu.curation.list'),
            to: '/curation/list',
          },
          {
            title: this.$i18n.t('menu.synchro_lines.list'),
            to: '/synchro-lines/list',
            action : {
              icon: 'mdi-file-upload',
              title: this.$i18n.t('menu.synchro_lines.upload_imparfaite'),
              to: '/synchro-lines/upload-imparfaite',
            },
          },
        ],
      },
      {
        icon: 'mdi-water-opacity',
        title: this.$i18n.t('menu.drops.list'),
        to: '/drops/list',
      },
      {
        icon: 'mdi-wallet',
        title: this.$i18n.t('menu.price_negotiations.list'),
        to: '/price_negotiations/list',
      },
      {
        icon: 'mdi-package-variant',
        title: this.$i18n.t('menu.logistic.list'),
        items: [
          {
            title: this.$i18n.t('menu.tracking.list'),
            to: '/tracking/list',
            action : {
              icon: 'mdi-file-upload',
              title: 'Importer des N°de suivi externe',
              to: '/tracking/import',
            },
          },
          {
            title: this.$i18n.t('menu.shipments.list'),
            to: '/shipments/list',
          },
          {
            title: this.$i18n.t('menu.returns.list'),
            to: '/returns/list',
          },
        ],
      },
    ]
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style lang="sass">

</style>
